<template>
  <div class="container">
    <RoleCard theme="school">
      <template #label>Приглашение на НТО</template>
      <template #label_img>
        <img
          src="@/assets/images/reg_school.svg"
          alt="иконка" />
      </template>
      <template #body>
        <BaseLoadingBox
          theme="reg"
          :pending="pending">
          <div v-if="!pending">
            <div
              v-if="error"
              class="mt-l mb-l">
              <div class="text-size-l text-bold mb-m">Ошибка</div>
              <div class="color-error">
                {{ error }}
              </div>
            </div>
            <div v-else>
              <div class="row">
                <div class="col-md-8 col-xs-12">
                  <div class="text-size-l text-bold">
                    {{ organization.full_name }}
                  </div>
                  <div v-if="organization.address">
                    {{ organization.address }}
                  </div>
                  <p class="mt-m mb-m">
                    Вас приглашают на Национальную технологическую олимпиаду!
                  </p>
                  <p v-if="!user">
                    Если вы&nbsp;примете приглашение, эта школа будет указана
                    в&nbsp;вашем профиле как ваше учебное заведение. Чтобы
                    принять приглашение, войдите в&nbsp;свою учетную запись
                    на&nbsp;платформе &laquo;Талант&raquo; или создайте учетную
                    запись, если у&nbsp;вас ее&nbsp;нет.
                  </p>
                  <p v-else-if="isParticipant">
                    Если вы учитесь в этой школе, нажмите «Да», и мы добавим ее
                    в вашу учетную запись на платформе «Талант». Если вы учитесь
                    в другой школе, нажмите «Нет».
                  </p>
                </div>
              </div>
              <hr class="hr" />

              <!-- Если не авторизован  -->

              <BaseButton
                v-if="!user"
                tag="a"
                :href="loginUrl"
                >Войти или Зарегистрироваться</BaseButton
              >
              <template v-else-if="isParticipant">
                <BaseButton
                  class="mb-s mr-s"
                  :loading="confirmPending"
                  @click.prevent="handleConfirm"
                  >Да, я здесь учусь</BaseButton
                >

                <BaseButton
                  theme="reg"
                  :to="{ name: 'user-index' }"
                  tag="router-link"
                  >Нет, я здесь не учусь</BaseButton
                >
              </template>
            </div>
          </div>
        </BaseLoadingBox>
      </template>
    </RoleCard>
  </div>
</template>

<script>
/**
 * @page Страница приглашения пользователя, принять участие в НТО
 * от конкретной школы
 *
 * 1. Если юзер авторизован в таланте, нужно установить связь с образовательной организаией
 * 2. Если такая связь уже есть то перенаправляем пользователя, в зависимости от статуса участия
 * либо на экран реги, либо в лк
 * 3. Просим войти в аккаунт таланта, если не авторизован
 */
import RoleCard from "@/components/registration/RoleCard";
import { request, talentRequest } from "@/services/api";
export default {
  name: "InviteToSchool",
  metaInfo() {
    return {
      title: "Приглашение в НТО от школы",
    };
  },
  components: {
    RoleCard,
  },
  data() {
    return {
      pending: true,
      error: "",
      confirmPending: false,
    };
  },
  computed: {
    loginUrl() {
      return `${
        this.$store.state.clientApiPrefix
      }/auth/go?next=${encodeURIComponent(this.$route.fullPath)}`;
    },
    user() {
      return this.$store.getters["user/user"];
    },
    isParticipant() {
      return this.$store.getters["user/isParticipant"];
    },
    isMentor() {
      return this.$store.getters["user/isMentor"];
    },
  },
  created() {
    this.init();
  },
  methods: {
    async handleConfirm() {
      if (this.confirmPending) return;
      this.confirmPending = true;
      try {
        const grade = this.user.participant?.grade || 1;
        await talentRequest({
          method: "POST",
          url: `/api/users/${this.user.talent_id}/organizations/${this.$route.query.org}/member/invite/`,
          data: {
            code: this.$route.query.code,
            user: this.user.talent_id,
            event: +this.$route.query.event,
            education: {
              extra: {},
              start: new Date().getFullYear() - grade,
            },
          },
        });

        const educations = await this.getEducations();

        const education_id = educations.find(
          (n) => n.organization === +this.$route.query.org
        )?.id;
        if (education_id) {
          // Обновим данные об образовании в таланте
          this.$store.dispatch("user/updateUserInfo", {
            current_education: education_id,
            grade,
          });
          await request({
            method: "PATCH",
            url: "/participant",
            data: {
              talent_education_id: education_id,
            },
          });
          this.$store.commit("participant/SET_STATE", {
            key: "talent_education_id",
            value: education_id,
          });
        }
      } catch (error) {
        console.log("error", error);
      }
      this.$router.push({ name: "user-index" });
      this.confirmPending = false;
    },
    async getOrg(org) {
      const { data } = await talentRequest({
        method: "GET",
        url: `/api/organizations/${org}/`,
      });
      this.organization = data;
    },
    async getEducations() {
      const { data } = await talentRequest({
        url: `/api/users/${this.user.talent_id}/educations/`,
        params: {
          limit: 100,
          offset: 0,
        },
      });
      return data?.results;
    },
    async init() {
      const { user } = this;
      const { org, code } = this.$route.query;
      try {
        if (!org || !code) {
          throw "Ссылка по которой вы перешли - неправильная. Пожалуйста, запросите новую ссылку";
        }
        try {
          await this.getOrg(org);
          if (!this.organization.educational_category) {
            throw "Организация не является образовательным учреждением";
          }
        } catch (error) {
          throw `Не удалось получить данные организации ${org}. Запросите ссылку-приглашение повторно.`;
        }
        // если не авторизован
        if (!user?.talent_id) return;
        else if (this.isMentor)
          throw "Это приглашение не действительно для наставников.";
        else if (!this.isParticipant) {
          // Если еще не зарегна на НТО, отправляем регаться;
          this.$router.replace({
            name: "registration-school",
            query: {
              org: this.$route.query.org,
              org_invite: this.$route.query.code,
              event: this.$route.query.event,
            },
          });
          return;
        } else if (this.isParticipant) {
          // Если участник, то нужно проверить образования юзера
          // есть ли у него это образование или нет
          try {
            const educations = await this.getEducations();

            const isMember = educations.find(
              (n) => n.organization === +this.$route.query.org
            );
            //  Если уже есть такое образование, то перекинем на главную
            if (isMember) {
              this.$router.replace({ name: "user-index" });
              return;
            }
          } catch (error) {
            throw "Не удалось получить список образований пользователя. Пожалуйста, сообщите нам об этой ошибке.";
          }
        }
      } catch (msg) {
        if (typeof msg === "string") {
          this.error = msg;
        } else {
          this.error = "Возникла непредвиденная ошибка.";
        }
      } finally {
        this.pending = false;
      }
    },
  },
};
</script>

<style></style>
